.docs-preview {
  margin-top: space(3x);
  border: $border-width-xxs solid $border-color-assertive;
  padding: space(2x);
  background-image: linear-gradient(45deg, rgba($neutral-95, 0.5) 25%, transparent 25%),
    linear-gradient(135deg, rgba($neutral-95, 0.5) 25%, transparent 25%),
    linear-gradient(45deg, transparent 75%, rgba($neutral-95, 0.5) 75%),
    linear-gradient(135deg, transparent 75%, rgba($neutral-95, 0.5) 75%);
  background-position:
    0 0,
    space(lg) 0,
    space(lg) calc(0rem - space(lg)),
    0 space(lg);
  background-size: space(3x) space(3x);

  &.dark {
    border-bottom: $border-width-xxs solid $border-color-assertive;
    background-color: $neutral-20;
    background-image: linear-gradient(45deg, rgba($neutral-00, 0.5) 25%, transparent 25%),
      linear-gradient(135deg, rgba($neutral-00, 0.5) 25%, transparent 25%),
      linear-gradient(45deg, transparent 75%, rgba($neutral-00, 0.5) 75%),
      linear-gradient(135deg, transparent 75%, rgba($neutral-00, 0.5) 75%);
  }

  &.docs-preview-flex {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: space(2x);
  }

  > :first-child {
    margin-top: 0;
  }

  .bsds-nav-tertiary {
    width: 100%; // TODO: remove this when we remove the fixed widths from the page layout
  }

  .docs-icon-decorative {
    > * {
      margin-top: 0;
    }
  }
}

// NOTE: This is used on external examples e.g. /components/button/example/default
.docs-preview-external {
  margin: space(4x);
}
