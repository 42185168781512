// TODO: ADS-129 fix layout imports

*,
::after,
::before {
  box-sizing: border-box;
}

body {
  margin: 0;
  background: $neutral-100;
}

.docs-root {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

// TODO: ADS-410
main {
  img {
    width: 100%;
    margin-top: space(3x);
  }
}

.docs-body {
  display: grid;
  grid-template-areas: 'nav-secondary' 'main';
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr;
  flex: 1;

  .bsds-nav-secondary {
    grid-area: nav-secondary;
  }

  main {
    display: grid;
    grid-area: main;
    grid-template-areas:
      'intro'
      'nav-tertiary'
      'page-content';
    grid-template-columns: 1fr;
    grid-template-rows: auto auto 1fr;
    justify-content: start;
    justify-items: start;
    overflow-y: auto;
    padding: space(4x) calc(space(4x) - space(md));
  }
}

.docs-page-header {
  grid-area: intro;
  width: 100%;
  padding: 0 space(sm);
}

.docs-storybook-link {
  display: inline-block;
  margin-top: space(lg);
}

.docs-page-content {
  grid-area: page-content;
  width: 100%;
  overflow-x: auto;
  padding: 0 space(sm);

  /* stylelint-disable-next-line selector-class-pattern */
  > .docs-preview- {
    &:first-of-type {
      margin-top: space(6x);
    }
  }
}

.docs-title {
  margin-top: 0;
}

.docs-datestamp {
  margin-top: 0;
  @include font-size($fs-body-subtle);

  dt,
  dd {
    display: inline;
  }

  dd {
    margin-bottom: 0;
    margin-left: space(md);
  }
}

.docs-footer {
  margin-top: space(12x);
}

.docs-body-minimal {
  flex: 1;
  width: var(--app-width);
  margin: 0 auto;

  main {
    padding: space(4x) calc(space(4x) - space(md));
  }

  h1 {
    margin-top: 0;
  }
}

@media (min-width: $breakpoint-lg-min) {
  .docs-body {
    grid-template-areas: 'nav-secondary main';
    grid-template-columns: 1fr 4fr;
    width: var(--app-width);
    margin-right: auto;
    margin-left: auto;
    gap: 0 space(4x) - space(md);

    main {
      display: grid;
      grid-template-areas:
        'intro nav-tertiary'
        'page-content nav-tertiary';
      grid-template-columns: 4fr 1fr;
      grid-template-rows: auto 1fr;
      gap: 0 space(4x);
      justify-content: start;
      justify-items: start;
      margin-top: space(4x);
      padding: 0 space(sm);
    }

    > .bsds-nav-secondary {
      width: 12rem; // NOTE: this is a one-off size
      margin-top: space(4x);
    }

    .bsds-nav-tertiary {
      width: 12rem; // NOTE: this is a one-off size
      margin-top: space(lg);
    }
  }

  .docs-body-minimal {
    main {
      margin-top: space(4x);
      padding: 0 space(sm);
    }
  }
}

@media (min-width: $breakpoint-xl-min) {
  .docs-body {
    grid-template-columns: 1fr 5fr;
    width: var(--app-width);
  }
}

@media (min-width: $breakpoint-xxl-min) {
  .docs-body {
    width: var(--app-width);
  }
}
