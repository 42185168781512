.docs-wire-header,
.docs-wire-main {
  padding: 0 space(2x) space(2x);
  text-align: center;
}

.docs-wire-header {
  border-bottom: $border-width-xxs solid $border-color-subtle;
}

.docs-wire-main {
  height: 100vh;
  background: $neutral-95;
}

.docs-wire-card {
  width: 100%;
  padding: space(6x) space(lg);
  background: $neutral-95;
  text-align: center;
}
