.docs-team-member-card {
  display: flex;
  align-items: center;
  border-top: $border-width-xxs solid $border-color-subtle;
  padding: space(2x) 0;

  &:first-of-type {
    margin-top: space(4x);
  }

  &:last-of-type {
    border-bottom: $border-width-xxs solid $border-color-subtle;
  }

  img {
    width: $size-icon-decorative;
    height: $size-icon-decorative;
    margin: 0;
    border-radius: $border-radius-circle;
  }

  .docs-team-member {
    margin-left: space(4x);
  }

  .docs-team-member-name {
    margin: 0;
    @include font-family-base-heavy;
  }

  .docs-team-member-title {
    margin: 0;
  }
}
